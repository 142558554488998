define("discourse/plugins/discourse-calendar/discourse/components/event-date", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _guessBestDateFormat, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventDate extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{~#if this.shouldRender~}}
          <span class="header-topic-title-suffix-outlet event-date-container">
            {{~#if this.siteSettings.use_local_event_date~}}
              <span
                class="event-date event-local-date past"
                title={{this.dateRange}}
                data-starts-at={{this.eventStartedAt}}
                data-ends-at={{this.eventEndedAt}}
              >
                {{this.localDateContent}}
              </span>
            {{else}}
              <span
                class="event-date event-relative-date {{this.relativeDateType}}"
                title={{this.dateRange}}
                data-starts-at={{this.eventStartedAt}}
                data-ends-at={{this.eventEndedAt}}
              >
                {{~#if this.isWithinDateRange~}}
                  <span class="indicator"></span>
                  <span class="text">{{this.timeRemainingContent}}</span>
                {{else}}
                  {{this.relativeDateContent}}
                {{~/if~}}
              </span>
            {{~/if~}}
          </span>
        {{~/if~}}
      
    */
    {
      "id": "bxyp/X58",
      "block": "[[[41,[30,0,[\"shouldRender\"]],[[[10,1],[14,0,\"header-topic-title-suffix-outlet event-date-container\"],[12],[41,[30,0,[\"siteSettings\",\"use_local_event_date\"]],[[[10,1],[14,0,\"event-date event-local-date past\"],[15,\"title\",[30,0,[\"dateRange\"]]],[15,\"data-starts-at\",[30,0,[\"eventStartedAt\"]]],[15,\"data-ends-at\",[30,0,[\"eventEndedAt\"]]],[12],[1,\"\\n            \"],[1,[30,0,[\"localDateContent\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[15,0,[29,[\"event-date event-relative-date \",[30,0,[\"relativeDateType\"]]]]],[15,\"title\",[30,0,[\"dateRange\"]]],[15,\"data-starts-at\",[30,0,[\"eventStartedAt\"]]],[15,\"data-ends-at\",[30,0,[\"eventEndedAt\"]]],[12],[41,[30,0,[\"isWithinDateRange\"]],[[[10,1],[14,0,\"indicator\"],[12],[13],[1,\"\\n              \"],[10,1],[14,0,\"text\"],[12],[1,[30,0,[\"timeRemainingContent\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,0,[\"relativeDateContent\"]]]],[]]],[13]],[]]],[13]],[]],null]],[],false,[\"if\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/event-date.js",
      "isStrictMode": true
    }), this))();
    get shouldRender() {
      return this.siteSettings.discourse_post_event_enabled && this.args.topic.event_starts_at;
    }
    get eventStartedAt() {
      return this._parsedDate(this.args.topic.event_starts_at);
    }
    get eventEndedAt() {
      return this.args.topic.event_ends_at ? this._parsedDate(this.args.topic.event_ends_at) : this.eventStartedAt;
    }
    get dateRange() {
      return this.args.topic.event_ends_at ? `${this._formattedDate(this.eventStartedAt)} → ${this._formattedDate(this.eventEndedAt)}` : this._formattedDate(this.eventStartedAt);
    }
    get localDateContent() {
      return this._formattedDate(this.eventStartedAt);
    }
    get relativeDateType() {
      if (this.isWithinDateRange) {
        return "current";
      }
      if (this.eventStartedAt.isAfter(moment())) {
        return "future";
      }
      return "past";
    }
    get isWithinDateRange() {
      return this.eventStartedAt.isBefore(moment()) && this.eventEndedAt.isAfter(moment());
    }
    get relativeDateContent() {
      // dateType "current" uses a different implementation
      const relativeDates = {
        future: this.eventStartedAt.from(moment()),
        past: this.eventEndedAt.from(moment())
      };
      return relativeDates[this.relativeDateType];
    }
    get timeRemainingContent() {
      return _discourseI18n.default.t("discourse_post_event.topic_title.ends_in_duration", {
        duration: this.eventEndedAt.from(moment())
      });
    }
    _parsedDate(date) {
      return moment.utc(date).tz(moment.tz.guess());
    }
    _guessedDateFormat() {
      return (0, _guessBestDateFormat.default)(this.eventStartedAt);
    }
    _formattedDate(date) {
      return date.format(this._guessedDateFormat());
    }
  }
  _exports.default = EventDate;
});